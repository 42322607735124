const { firebase } = require("users/firebase/compat");

const firebaseui = require("firebaseui");
const Sentry = require("sentry");

const ui = new firebaseui.auth.AuthUI(firebase.auth());

// console.log(firebaseui);

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            console.log(authResult, redirectUrl);
            // check if the user has access to the app

            checkAccess();

            return false;
        },
    },
    signInSuccessUrl: "/",
    signInFlow: "popup",
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    tosUrl: "/ws/terms",
    privacyPolicyUrl: "/ws/privacy",
};

ui.start("#firebaseui-auth-container", uiConfig);

function displayError(message) {
    console.log(message);
    $("#message").fadeOut();
    $("#alert").fadeIn();

    let html = message;

    if (message.includes("verify your email")) {
        html +=
            '<br><a href="#verify-email" class="btn btn-primary">Verify Now</a>';
    }

    if (message.includes("Waiting for Authorization Token")) {
        html +=
            '<br><a href="#check-access" class="btn btn-primary">Retry</a> <a onclick="location.reload()" class="btn btn-secondary float-right">Reload</a>';
    }

    $("#alert").html(html);
}

function displayMessage(message) {
    console.log(message);
    $("#alert").fadeOut();
    $("#message").fadeIn();

    let html = message;

    if (message.includes("verify your email")) {
        html +=
            '<br><a href="#verify-email" class="btn btn-primary">Verify Now</a>';
    }

    $("#message").html(html);
}

window.addEventListener("hashchange", function (event) {
    if (location.hash === "#verify-email") {
        sendVerificationEmail();
        location.hash = "";
    }

    if (location.hash === "#check-access") {
        checkAccess();
        location.hash = "";
    }
});

/**
 * Check if the user is signed in properly
 */
function checkAccess(redirectUrl, retryCount) {
    if (!retryCount) {
        retryCount = 1;
    } else {
        retryCount++;
    }

    return fetch("/login/access")
        .then(async function (result) {
            let json = await result.json();

            displayLoggedInUser(json);

            if (json.error) {
                try {
                    if (
                        json.error.includes(
                            "Waiting for Authorization Token"
                        ) &&
                        retryCount < 3
                    ) {
                        setTimeout(() => {
                            checkAccess(redirectUrl, retryCount);
                        }, 500);
                        return;
                    } else {
                        // log the error to sentry
                        Sentry.withScope(scope => {
                            scope.setExtra(
                                "service worker available",
                                !!navigator.serviceWorker
                            );

                            if (
                                navigator.serviceWorker &&
                                navigator.serviceWorker.controller
                            ) {
                                scope.setExtra(
                                    "service-worker-controller",
                                    navigator.serviceWorker.controller
                                );
                            } else {
                                scope.setExtra(
                                    "service-worker-controller",
                                    "not defined"
                                );
                            }

                            Sentry.captureException(new Error(json.error));
                        });
                    }
                    // eslint-disable-next-line no-empty
                } catch (err) {}
                displayError(json.error);
            } else {
                if (json.access) {
                    let redirect = "/";

                    if (json.redirect) {
                        redirect = json.redirect;
                    } else if (redirectUrl) {
                        redirect = redirectUrl;
                    }

                    try {
                        // check the url params for the redirect url
                        const params = new URLSearchParams(location.search);
                        const redirectText = params.get("redirect");
                        if (redirectText) {
                            const url = new URL(redirectText);

                            // ensure that the redirect url goes to the same site.
                            url.hostname = location.hostname;

                            redirect = url.toString();
                        }
                    } catch (err) {
                        // no-op. If this fails don't modify the redirect
                    }

                    location.assign(redirect);
                }
            }
        })
        .catch(function (err) {
            displayError(err.message);
        });
}

function sendVerificationEmail() {
    const user = firebase.auth().currentUser;

    user.sendEmailVerification()
        .then(function () {
            displayMessage(
                'Verification email sent. Please follow the link to verify and then try signing in again. <br><a href="/login">Reload</a>'
            );
        })
        .catch(function (err) {
            displayError(err.message);
        });
}

// Check if the user is already logged into firebase and just needs a session refresh
(async () => {
    const result = await fetch("/login/access");

    const data = await result.json();
    displayLoggedInUser(data);
})().catch(err => {
    console.warn("Initial Check Failed", err);
});

// setup the logged in user event handlers
setTimeout(() => {
    document.getElementById("logged-in-link").addEventListener("click", () => {
        $("#logged-in-link").fadeOut();
        $("#logged-in-message").fadeIn();
    });

    document
        .getElementById("retry-login-button")
        .addEventListener("click", event => {
            displayMessage("Checking Access...");
            event.target.classList.add("disabled");

            setTimeout(() => {
                checkAccess();
            }, 1500);

            setTimeout(() => {
                event.target.classList.remove("disabled");
            }, 10000);
        });
}, 250);

function displayLoggedInUser(data) {
    if (data.user) {
        // document.getElementById( "logged-in-link" ).href = data.redirect ? data.redirect : "/";

        document.getElementById("logged-in-name").innerText = data.user.name;
        document.getElementById("logged-in-email").innerText = data.user.email;
        $("#logged-in-user").fadeIn();

        document.getElementById("logged-in-image").src = data.user.picture;

        const button = document.getElementById("logged-in-link");
        if (!button) {
            return;
        }

        if (data.access) {
            button.classList.remove("disabled");

            // hide the retry button
            $("#retry-login-parent").fadeOut();
        } else {
            button.classList.add("disabled");

            displayError(
                "You don't have access to the App. Please contact an Administrator if this is an error."
            );

            // show the retry button
            $("#retry-login-parent").fadeIn();
        }
    }
}
