require( "./../env.js" );
require( "./../../public_html/source/groups/global/prototype/htmlElement" );
// require("./../../")
// load and setup sentry for error tracking
require( "sentry" );

// eslint-disable-next-line no-console
console.log( "Log In Page" );

require( "./signin/firebase-setup" );

import { setupServiceWorker } from "serviceWorker";


setupServiceWorker()
    .then( () => {
        setTimeout( () => {
            $( "#installing" ).hide();
            $( "#login" ).fadeIn();
        }, 500 );
    } );